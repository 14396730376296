<template>
  <div>
    <pageHeader />
    <div>
      <h1 class="text-center">Salon Calendar</h1>
    </div>
  </div>
</template>

<script>
import pageHeader from "@/components/pageHeader.vue";

export default {
  name: "salonCalendar",
  components: { pageHeader },
  data() {
    return {};
  },
};
</script>

<style></style>
